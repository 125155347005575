import { motion } from "framer-motion";

const LoadingPage = () => {
  return (
    <div className="h-[100dvh] flex justify-center items-center">
      <motion.svg
        viewBox="0 46.987 100 22.375"
        xmlns="http://www.w3.org/2000/svg"
        className="w-[333px]"
        stroke="#ccc"
        // initial={{ scale: 1, sopacity: 1 }}
        animate={{ scale: [1, 1, 1.5], opacity: [1, 1, 0], x: [25, 0, -25] }}
        transition={{ duration: 1.5, times: [0, 0.6, 0.9] }}
      >
        <rect
          x={-54.916}
          y={66.506}
          width={54.733}
          height={0.494}
          style={{}}
          transform="matrix(-1, 0, 0, 1, -1.4210854715202004e-14, 0)"
        />
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M8.892,55.008h87.716c-0.279-0.922-1.398-3.043-10.465-14.945l-0.218-0.285c-1.141-1.503-3.269-4.302-6.58-4.302H12.203   c-3.506,0-3.311,2.853-3.311,6.358V55.008z M71.097,38.054h7.792c1.094,0,1.965,1.161,2.548,1.928s6.391,8.015,6.599,8.896   c0.205,0.881-0.028,1.982-1.984,1.982H71.358c-1.094,0-1.98-0.887-1.98-1.982l-0.267-8.84   C69.111,38.942,69.999,38.054,71.097,38.054z M48.042,40.56c0-1.491,0.934-2.699,2.083-2.699h5.827   c1.148,0,2.082,1.208,2.082,2.699v7.551c0,1.492-0.934,2.7-2.082,2.7h-5.827c-1.149,0-2.083-1.208-2.083-2.7V40.56z M31.875,40.56   c0-1.491,0.933-2.699,2.084-2.699h5.826c1.151,0,2.083,1.208,2.083,2.699v7.551c0,1.492-0.933,2.7-2.083,2.7h-5.826   c-1.151,0-2.084-1.208-2.084-2.7V40.56z M16.297,40.56c0-1.491,0.932-2.699,2.083-2.699h5.826c1.151,0,2.083,1.208,2.083,2.699   v7.551c0,1.492-0.933,2.7-2.083,2.7h-5.826c-1.152,0-2.083-1.208-2.083-2.7V40.56z" />
          <path d="M8.891,56.592c-0.01,3.23,0.003,5.773,3.312,5.773h78.203c2.964,0,4.534-1.193,5.325-2.193   c0.789-0.996,1.175-2.234,1.125-3.58H8.891z" />
        </g>
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M89.34,62.375L89.34,62.375c0-1.754-1.422-3.176-3.18-3.176c-1.753,0-3.176,1.422-3.176,3.176l0,0H89.34z" />
          <path d="M83.089,63.17c0.351,1.369,1.591,2.385,3.071,2.385c1.481,0,2.723-1.016,3.076-2.385H83.089z" />
        </g>
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M82.19,62.375L82.19,62.375c0-1.361,0.689-2.564,1.737-3.279c-0.634-0.582-1.478-0.938-2.404-0.938   c-1.97,0-3.565,1.598-3.565,3.564c0,0.225,0.022,0.441,0.061,0.652H82.19z" />
          <path d="M78.264,63.17c0.556,1.246,1.805,2.117,3.26,2.117c0.57,0,1.109-0.135,1.588-0.373c-0.413-0.494-0.706-1.092-0.838-1.744   H78.264z" />
        </g>
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M14.644,63.17c0.469,1.416,1.806,2.443,3.383,2.443c0.571,0,1.11-0.137,1.588-0.375c-0.479-0.574-0.796-1.289-0.888-2.068   H14.644z" />
          <path d="M18.711,62.375c0.1-1.225,0.759-2.295,1.722-2.955c-0.634-0.578-1.479-0.934-2.406-0.934c-1.968,0-3.563,1.596-3.563,3.564   c0,0.109,0.006,0.217,0.016,0.324H18.711z" />
        </g>
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M25.908,62.375c-0.085-1.719-1.501-3.086-3.242-3.086c-1.74,0-3.156,1.367-3.241,3.086H25.908z" />
          <path d="M19.479,63.17c0.293,1.492,1.608,2.619,3.188,2.619c1.58,0,2.896-1.127,3.188-2.619H19.479z" />
        </g>
        <g
          style={{}}
          transform="matrix(-0.5466110110282898, 0, 0, 0.5466110110282898, 54.8427619934082, 30.37702369689941)"
        >
          <path d="M5.356,41.834c0-3.505,0.194-6.358-3.312-6.358h-67.143c-3.311,0-5.438,2.799-6.581,4.302l-0.216,0.285   c-9.068,11.902-10.188,14.023-10.467,14.945H5.356V41.834z M-54.865,40.037l-0.264,8.84c0,1.095-0.888,1.982-1.984,1.982h-14.692   c-1.956,0-2.189-1.101-1.982-1.982c0.206-0.881,6.015-8.128,6.599-8.896c0.583-0.767,1.452-1.928,2.547-1.928h7.793   C-55.752,38.054-54.865,38.942-54.865,40.037z M-33.795,48.111c0,1.492-0.933,2.7-2.083,2.7h-5.826c-1.151,0-2.083-1.208-2.083-2.7   V40.56c0-1.491,0.933-2.699,2.083-2.699h5.826c1.15,0,2.083,1.208,2.083,2.699V48.111z M-17.628,48.111   c0,1.492-0.932,2.7-2.083,2.7h-5.826c-1.15,0-2.083-1.208-2.083-2.7V40.56c0-1.491,0.934-2.699,2.083-2.699h5.826   c1.151,0,2.083,1.208,2.083,2.699V48.111z M-2.05,48.111c0,1.492-0.933,2.7-2.084,2.7H-9.96c-1.149,0-2.083-1.208-2.083-2.7V40.56   c0-1.491,0.934-2.699,2.083-2.699h5.826c1.151,0,2.084,1.208,2.084,2.699V48.111z" />
          <path d="M5.357,56.592c0.008,3.23-0.004,5.773-3.312,5.773h-78.202c-2.967,0-4.534-1.193-5.326-2.193   c-0.792-0.996-1.174-2.234-1.126-3.58H5.357z" />
        </g>
      </motion.svg>
    </div>
  );
};

export default LoadingPage;
